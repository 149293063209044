<template>

  <b-modal ref="selectShopItemModal" hide-footer
    :title="$t('PAGES.SHOPITEM.SELECT')">

    <b-row>
      <b-col lg="12">
        <b-table
            id="shop-items-table"
            :fields="shop_item_headers"
            :items="shop_items"
            head-variant="light"
            sticky-header
            class="mh-100"
        >
          <template #cell(shop_item_id)="row">
            <div class='justify-content-end d-flex'>

                <a
                href="#"
                class="btn btn-icon btn-light btn-sm mr-3"
                @click="selectShopItemRowClicked(row.item.shop_item_id, row.item.name)"
                >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                    <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                </span>
                </a>

            </div>
          </template>
        </b-table>

      </b-col>
    </b-row>

    <b-row>
      <b-col>
          <b-button class="" variant="outline-secondary" block @click="onCancel">{{$t('COMMON.CANCEL')}}</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>
<style lang="scss" scoped>

</style>

<script>
import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: "shop_order_item-table",
  props: ["shop_order"],
  emits: ['selectedShopItem'],
  mixins: [ toasts ],
  components: {
    
  },
  methods: {
    selectShopItemRowClicked(shop_item_id, name) {
      this.$emit('selectedShopItem', shop_item_id, name);
      this.$refs['selectShopItemModal'].hide();
    },
    onCancel() {
      this.$refs['selectShopItemModal'].hide();
    },
    show() {
      this.$refs['selectShopItemModal'].show();

      this.fetchAlternativeShopItemsForOrder(this.shop_order);
    },
    fetchAlternativeShopItemsForOrder(shop_order) {
      axios
        .get(`/shop_item/alternative_for_order/${shop_order.shop_order_id}`)
        .then(res => {
          if (res.status === 200) {
            this.shop_items = res.data;
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_LIST_SHOPITEMS'));
          }
        })
        .catch(err => {
          console.error(err);
        });
    },

  },
  data() {
    return {
      shop_items: [],
      shop_item_headers: [
          {
          key: 'name',
          label: 'Namn',
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7'
        },
        {
          key: 'price_sek',
          label: 'Kostnad',
          sortable: true,
          thClass: 'w-140px align-middle',
          tdClass: 'w-140px',
        },
        {
          key: 'shop_item_id',
          label: 'Välj',
          thClass: 'text-right pr-22 w-110px align-middle',
        },
      ],

      perPage: 25,
      currentPage: 1,
      options: [25, 50, 100],
      showFields: [
        { key: 'name', label: this.$t('PAGES.SHOPITEM.ARTICLE'), show: true, sortable: true, thClass: 'pl-7 w-100', tdClass: 'pl-7'},
        { key: 'actions', label: this.$t('COMMON.ACTIONS'), show: true, sortable: false}
      ],
      filter: '',
      list: [

      ]
    };
  }
};
</script>

